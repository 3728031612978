var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay"},[_c('page-title',[_vm._v(_vm._s(_vm.i18n.MENU.order))]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.orderProcess)}}},[_c('div',{staticClass:"width"},[_c('dl',{staticClass:"user-info"},[_c('dt',{staticClass:"user-info__title"},[_vm._v(_vm._s(_vm.i18n.ORDER.NAME)+":")]),_c('dd',{staticClass:"user-info__text"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderName),expression:"orderName"}],staticClass:"order__text",attrs:{"type":"text","disabled":"disabled","name":"order_name"},domProps:{"value":(_vm.orderName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.orderName=$event.target.value}}}),_c('label',{staticClass:"error"},[_vm._v(_vm._s(_vm.i18n['VALIDATE'][errors[0]]))])]}}],null,true)})],1),_c('dt',{staticClass:"user-info__title"},[_c('a',{attrs:{"target":"_blank","href":"http://www.ukrstat.gov.ua/menu/edrpou.htm"}},[_vm._v(_vm._s(_vm.i18n.ORDER.EDRPOU))]),_vm._v(":")]),_c('dd',{staticClass:"user-info__text"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderEdrpou),expression:"orderEdrpou"}],staticClass:"order__text",attrs:{"type":"text","disabled":"disabled","name":"order_name"},domProps:{"value":(_vm.orderEdrpou)},on:{"input":function($event){if($event.target.composing){ return; }_vm.orderEdrpou=$event.target.value}}})]),_c('dt',{staticClass:"user-info__title"},[_vm._v(_vm._s(_vm.i18n.ORDER.PHONE)+":")]),_c('dd',{staticClass:"user-info__text"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"order__text",attrs:{"type":"text","disabled":"disabled","name":"order_phone","options":_vm.phoneOptions,"raw":false,"placeholder":"+380 __ ___ ____"},model:{value:(_vm.orderPhone),callback:function ($$v) {_vm.orderPhone=$$v},expression:"orderPhone"}}),_c('label',{staticClass:"error"},[_vm._v(_vm._s(_vm.i18n['VALIDATE'][errors[0]]))])]}}],null,true)})],1),_c('dt',{staticClass:"user-info__title"},[_vm._v(_vm._s(_vm.i18n.ORDER.ADDRESS)+":")]),_c('dd',{staticClass:"user-info__text"},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderEmail),expression:"orderEmail"}],staticClass:"order__text",attrs:{"type":"text","disabled":"disabled","name":"order_email"},domProps:{"value":(_vm.orderEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.orderEmail=$event.target.value}}}),_c('label',{staticClass:"error"},[_vm._v(_vm._s(_vm.i18n['VALIDATE'][errors[0]]))])]}}],null,true)})],1),_c('dt',{staticClass:"user-info__title"},[_vm._v(_vm._s(_vm.i18n.ORDER.CITY)+":")]),_c('dd',{staticClass:"user-info__text"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderCity),expression:"orderCity"}],staticClass:"order__text",attrs:{"type":"text"},domProps:{"value":(_vm.orderCity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.orderCity=$event.target.value}}})]),_c('dt',{staticClass:"user-info__title"},[_vm._v(_vm._s(_vm.i18n.ORDER.ADDITIONAL)+":")]),_c('dd',{staticClass:"user-info__text"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderText),expression:"orderText"}],staticClass:"order__text",domProps:{"value":(_vm.orderText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.orderText=$event.target.value}}})])]),_c('div',{staticClass:"size"},[_c('div',{staticClass:"size__title"},[_vm._v(" "+_vm._s(_vm.i18n.ORDER.SELECT_SIZE)+" ")]),_c('div',{staticClass:"size__text"},[_vm._v(" "+_vm._s(_vm.i18n.ORDER.W)+" "),_c('validation-provider',{attrs:{"rules":("required|number_between:" + _vm.minWidth + "," + _vm.maxWidth)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order_width),expression:"order_width"}],staticClass:"size__field",attrs:{"type":"number","step":"1"},domProps:{"value":(_vm.order_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.order_width=$event.target.value}}}),_c('label',{staticClass:"error"},[_c('error-label',{attrs:{"min":_vm.minWidth,"max":_vm.maxWidth,"label":_vm.i18n['VALIDATE'][errors[0]]}})],1)]}}],null,true)}),_vm._v(" - "+_vm._s(_vm.i18n.ORDER.H)+" "),_c('validation-provider',{attrs:{"rules":("required|number_between:" + _vm.minLength + "," + _vm.maxLength)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order_height),expression:"order_height"}],staticClass:"size__field",attrs:{"type":"number","step":"1"},domProps:{"value":(_vm.order_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.order_height=$event.target.value}}}),_c('label',{staticClass:"error"},[_c('error-label',{attrs:{"min":_vm.minLength,"max":_vm.maxLength,"label":_vm.i18n['VALIDATE'][errors[0]]}})],1)]}}],null,true)}),_vm._v(" "+_vm._s(_vm.i18n.ORDER.MM)+" ")],1)]),_c('div',{staticClass:"size"},[_c('div',{staticClass:"size__title",domProps:{"innerHTML":_vm._s(_vm.i18n.ORDER.SELECT_WING)}}),_c('div',{staticClass:"size__text"},[_vm._v(" "+_vm._s(_vm.i18n.ORDER.SELECT_WING_SIZE)+" "),_c('validation-provider',{attrs:{"rules":("number_between:" + _vm.minWidth + "," + _vm.maxWidth)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order_wing),expression:"order_wing"}],staticClass:"size__field",attrs:{"type":"number","step":"1"},domProps:{"value":(_vm.order_wing)},on:{"input":function($event){if($event.target.composing){ return; }_vm.order_wing=$event.target.value}}}),_c('label',{staticClass:"error"},[_c('error-label',{attrs:{"min":_vm.minWidth,"max":_vm.maxWidth,"label":_vm.i18n['VALIDATE'][errors[0]]}})],1)]}}],null,true)}),_vm._v(" "+_vm._s(_vm.i18n.ORDER.MM)+" ")],1)]),_c('div',{staticClass:"size"},[_c('div',{staticClass:"size__title",domProps:{"innerHTML":_vm._s(_vm.i18n.ORDER.SELECT_BOTH_SIZE)}}),_c('div',{staticClass:"size__text"},[_vm._v(" "+_vm._s(_vm.i18n.ORDER.W)+" "),_c('validation-provider',{attrs:{"rules":("required|number_between:" + _vm.minWidth + "," + _vm.maxWidth)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order_both_width),expression:"order_both_width"}],staticClass:"size__field",attrs:{"type":"number","step":"1"},domProps:{"value":(_vm.order_both_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.order_both_width=$event.target.value}}}),_c('label',{staticClass:"error"},[_c('error-label',{attrs:{"min":_vm.minWidth,"max":_vm.maxWidth,"label":_vm.i18n['VALIDATE'][errors[0]]}})],1)]}}],null,true)}),_vm._v(" - "+_vm._s(_vm.i18n.ORDER.H)+" "),_c('validation-provider',{attrs:{"rules":("required|number_between:" + _vm.minLength + "," + _vm.maxLength)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order_both_height),expression:"order_both_height"}],staticClass:"size__field",attrs:{"type":"number","step":"1"},domProps:{"value":(_vm.order_both_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.order_both_height=$event.target.value}}}),_c('label',{staticClass:"error"},[_c('error-label',{attrs:{"min":_vm.minLength,"max":_vm.maxLength,"label":_vm.i18n['VALIDATE'][errors[0]]}})],1)]}}],null,true)}),_vm._v(" "+_vm._s(_vm.i18n.ORDER.MM)+" ")],1)]),_c('div',{staticClass:"additionaly"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.maxSize)}})]),_c('div',{staticClass:"order-panel"},[_c('p',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderPanel),expression:"orderPanel"}],staticClass:"custom-checkbox",attrs:{"type":"checkbox","id":"panel"},domProps:{"checked":Array.isArray(_vm.orderPanel)?_vm._i(_vm.orderPanel,null)>-1:(_vm.orderPanel)},on:{"change":function($event){var $$a=_vm.orderPanel,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.orderPanel=$$a.concat([$$v]))}else{$$i>-1&&(_vm.orderPanel=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.orderPanel=$$c}}}}),_c('label',{attrs:{"for":"panel"}},[_vm._v(_vm._s(_vm.i18n.ORDER.COMPLETE))])])]),_c('div',{staticClass:"order-footer"},[_c('button',{attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.i18n.ORDER.CHECKOUT))])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }