<template>
  <div class="overlay">
    <page-title>{{i18n.MENU.order}}</page-title>
    <validation-observer v-slot="{ handleSubmit }">
      <form action="#"
            @submit.prevent="handleSubmit(orderProcess)">
        <div class="width">
          <dl class="user-info">
            <dt class="user-info__title">{{i18n.ORDER.NAME}}:</dt>
            <dd class="user-info__text">
              <validation-provider rules="required" v-slot="{ errors }">
                <input
                  type="text"
                  disabled="disabled"
                  name="order_name"
                  v-model="orderName"
                  class="order__text">
                <label class="error">{{ i18n['VALIDATE'][errors[0]] }}</label>
              </validation-provider>
            </dd>
            <dt class="user-info__title"><a target="_blank" href="http://www.ukrstat.gov.ua/menu/edrpou.htm">{{i18n.ORDER.EDRPOU}}</a>:</dt>
            <dd class="user-info__text">
              <input
                type="text"
                disabled="disabled"
                name="order_name"
                v-model="orderEdrpou"
                class="order__text">
            </dd>
            <dt class="user-info__title">{{i18n.ORDER.PHONE}}:</dt>
            <dd class="user-info__text">
              <validation-provider rules="required" v-slot="{ errors }">
                <cleave
                  type="text"
                  disabled="disabled"
                  name="order_phone"
                  :options="phoneOptions"
                  :raw="false"
                  v-model="orderPhone"
                  placeholder="+380 __ ___ ____"
                  class="order__text" />
                <label class="error">{{ i18n['VALIDATE'][errors[0]] }}</label>
              </validation-provider>
            </dd>
            <dt class="user-info__title">{{i18n.ORDER.ADDRESS}}:</dt>
            <dd class="user-info__text">
              <validation-provider rules="required|email" v-slot="{ errors }">
                <input
                  type="text"
                  disabled="disabled"
                  name="order_email"
                  v-model="orderEmail"
                  class="order__text">
                <label class="error">{{ i18n['VALIDATE'][errors[0]] }}</label>
              </validation-provider>
            </dd>
            <dt class="user-info__title">{{i18n.ORDER.CITY}}:</dt>
            <dd class="user-info__text">
              <input type="text"
                     v-model="orderCity"
                     class="order__text">
            </dd>
            <dt class="user-info__title">{{i18n.ORDER.ADDITIONAL}}:</dt>
            <dd class="user-info__text">
              <textarea
                v-model="orderText"
                class="order__text"></textarea>
            </dd>
          </dl>
          <div class="size">
            <div class="size__title">
              {{i18n.ORDER.SELECT_SIZE}}
            </div>
            <div class="size__text">
              {{i18n.ORDER.W}}
              <validation-provider :rules="`required|number_between:${minWidth},${maxWidth}`" v-slot="{ errors }">
                <input type="number"
                       step="1"
                       v-model="order_width"
                       class="size__field">
                <label class="error">
                  <error-label :min="minWidth"
                               :max="maxWidth"
                               :label="i18n['VALIDATE'][errors[0]]" />
                </label>
              </validation-provider>
              - {{i18n.ORDER.H}}
              <validation-provider :rules="`required|number_between:${minLength},${maxLength}`" v-slot="{ errors }">
                <input type="number"
                       step="1"
                       v-model="order_height"
                       class="size__field">
                <label class="error">
                  <error-label :min="minLength"
                               :max="maxLength"
                               :label="i18n['VALIDATE'][errors[0]]" />
                </label>
              </validation-provider>
              {{i18n.ORDER.MM}}
            </div>
          </div>

          <div class="size">
            <div class="size__title" v-html="i18n.ORDER.SELECT_WING" />
            <div class="size__text">
              {{i18n.ORDER.SELECT_WING_SIZE}}
              <validation-provider :rules="`number_between:${minWidth},${maxWidth}`" v-slot="{ errors }">
                <input type="number"
                       step="1"
                       v-model="order_wing"
                       class="size__field">
                <label class="error">
                  <error-label :min="minWidth"
                               :max="maxWidth"
                               :label="i18n['VALIDATE'][errors[0]]" />
                </label>
              </validation-provider>
              {{i18n.ORDER.MM}}
            </div>
          </div>

          <div class="size">
            <div class="size__title" v-html="i18n.ORDER.SELECT_BOTH_SIZE" />
            <div class="size__text">
              {{i18n.ORDER.W}}
              <validation-provider :rules="`required|number_between:${minWidth},${maxWidth}`" v-slot="{ errors }">
                <input type="number"
                       step="1"
                       v-model="order_both_width"
                       class="size__field">
                <label class="error">
                  <error-label :min="minWidth"
                               :max="maxWidth"
                               :label="i18n['VALIDATE'][errors[0]]" />
                </label>
              </validation-provider>
              - {{i18n.ORDER.H}}
              <validation-provider :rules="`required|number_between:${minLength},${maxLength}`" v-slot="{ errors }">
                <input type="number"
                       step="1"
                       v-model="order_both_height"
                       class="size__field">
                <label class="error">
                  <error-label :min="minLength"
                               :max="maxLength"
                               :label="i18n['VALIDATE'][errors[0]]" />
                </label>
              </validation-provider>
              {{i18n.ORDER.MM}}
            </div>
          </div>

          <div class="additionaly">
            <!-- standard and "minimum size" text -->
            <!-- {{ i18n.ORDER.DESCRIPTION({modelName, modelWidth, modelLength}) }} -->
            <!-- <br> -->
            <!-- "maximum size" text -->
            <div v-html="maxSize" />
          </div>
          <!--
          <div class="blade">
            <p>
              <input type="radio"
                     id="inside_1"
                     name="inside"
                     value="inside_default">
              <label for="inside_1">Вставне дверне заповнення</label>
            </p>
            <p>
              <input type="radio"
                     id="inside_2"
                     value="inside_one"
                     name="inside">
              <label for="inside_2">Одностороннє накладне дверне заповнення</label>
            </p>
          </div>
          -->
          <div class="order-panel">
            <p>
              <input type="checkbox"
                     class="custom-checkbox"
                     v-model="orderPanel"
                     id="panel">
              <label for="panel">{{i18n.ORDER.COMPLETE}}</label>
            </p>
          </div>
          <div class="order-footer">
            <button type="submit">{{i18n.ORDER.CHECKOUT}}</button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
  import PageTitle from '@/components/page-title/page-title.vue'
  import { API } from '@/constants/api'
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { ValidationProvider, ValidationObserver, extend, validate } from 'vee-validate'
  import { required, min_value, max_value, email } from 'vee-validate/dist/rules'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.ua.js'
  import ErrorLabel from '@/components/shared/error-label'
  import {drawPNG} from '@/utils/picture'

  extend('required', {
    ...required,
    message: 'REQUIRED',
  })

  extend('min_value', min_value)
  extend('max_value', max_value)

  extend('number_between', {
    async validate(value, { min_value, max_value }) {
      const res = await validate(value, `min_value:${parseInt(min_value, 10)}|max_value:${parseInt(max_value, 10)}`,)
      return res.valid
    },
    params: ['min_value', 'max_value'],
    message: 'BETWEEN'
  })

  extend('email', {
    ...email,
    message: 'EMAIL',
  })

  export default {
    computed: {
      ...mapGetters('config', [
        'code',
        'shape',
        'model',
        'mirrored',
        'color',
        'house',
      ]),
      ...mapGetters('auth', [
        'isAuthenticated',
      ]),
      ...mapGetters('session', [
        'i18n',
      ]),
      minWidth() {
        return parseInt(this.model.min_model_width, 10) || 1150
      },
      maxWidth() {
        const isAluminium = this.color[this.house].is_alu !== '0'
        if (isAluminium) {
          return 1200
        }
        return 896
      },
      minLength() {
        return parseInt(this.model.min_model_height, 10) || 2300
      },
      maxLength() {
        const isAluminium = this.color[this.house].is_alu !== '0'
        if (isAluminium) {
          return 2300
        }
        return 2146
      },
      modelName() {
        return this.model.title
      },
      modelWidth() {
        return this.model.min_model_width || 1150
      },
      modelLength() {
        return this.model.min_model_height || 2300
      },
      maxSize() {
        return this.i18n.ORDER.MAX_SIZE
      },
    },
    components: {
      PageTitle,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      ErrorLabel,
    },
    created() {
      if (this.isAuthenticated) {
        this.loadUserData()
      }
    },
    methods: {
      async loadUserData() {
        let response
        try {
          response = await axios.get(API.USER_INFO)
        } catch (e) {
          console.error(e)
        }
        if (response?.data?.success) {
          this.orderName = response.data.name
          if (response.data.edrpou) {
            this.orderEdrpou = response.data.edrpou
          }
          this.orderCity = response.data.city
          this.orderEmail = response.data.email
          this.orderPhone = response.data.phone
        }
      },
      async getPictures() {
        const side = this.color.type === 'complete' ?
          'outside' :
          this.color.type
        await this.$store.dispatch('config/CONFIG_SET_HOUSE_VIEW', side)

        const svgImage1 = document.querySelector('svg.configurator__door--outside')
        const svgImage2 = document.querySelector('svg.configurator__door--inside')
        const svgImage1Box = svgImage1.getBBox()
        const svgWidth = parseInt(svgImage1Box.width, 10)
        const svgHeight = parseInt(svgImage1Box.height, 10)
        const serializer1 = await new XMLSerializer().serializeToString(svgImage1)
        const serializer2 = await new XMLSerializer().serializeToString(svgImage2)
        const base64String1 = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(serializer1)))
        const base64String2 = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(serializer2)))

        let base64PNG1
        let base64PNG2
        const isFlip = this.mirrored?.mirrored_type === 'mirrored' || false

        try {
          base64PNG1 = await drawPNG({
            svg: base64String1,
            width: svgWidth,
            height: svgHeight,
            flip: isFlip,
          })
        } catch (e) {
          console.error(e)
        }

        try {
          base64PNG2 = await drawPNG({
            svg: base64String2,
            width: svgWidth,
            height: svgHeight,
            flip: !isFlip,
          })
        } catch (e) {
          console.error(e)
        }

        if (!base64PNG1 || !base64PNG2) {
          return null
        }

        await this.$nextTick()
        return {
          outside: base64PNG1,
          inside: base64PNG2,
        }
      },
      async orderProcess() {
        const pictures = await this.getPictures()
        axios.post(API.POST_ORDER, {
          params: {
            name: this.orderName,
            edrpou: this.orderEdrpou,
            phone: this.orderPhone,
            email: this.orderEmail,
            city: this.orderCity,
            text: this.orderText,
            width: this.order_width,
            length: this.order_height,
            widthBoth: this.order_both_width,
            lengthBoth: this.order_both_height,
            wing: this.order_wing,
            panel: this.orderPanel,
            code: this.code,
            door: pictures,
          }
        })
          .then(response => {
            if (response.data.success === true) {
              this.orderName = ''
              this.orderEdrpou = ''
              this.orderPhone = ''
              this.orderEmail = ''
              this.orderCity = ''
              this.orderText = ''
              this.orderPanel = ''
            }
            this.$swal({
              title: 'Інформація',
              text: response.data.message,
              width: 375,
              confirmButtonText: 'OK',
              confirmButtonColor: '#C70552',
              customClass: {
                header: 'sweet-modal__header',
                title: 'sweet-modal__title',
                popup: 'sweet-modal__popup',
                content: 'sweet-modal__content',
                actions: 'sweet-modal__actions',
                confirmButton: 'sweet-modal__ok-button',
              },
            })
          })
      },
    },
    data() {
      return {
        orderName: '',
        orderEdrpou: '',
        orderPhone: '',
        orderEmail: '',
        orderCity: '',
        orderText: '',
        orderPanel: true,
        order_wing: 100,
        order_width: 800,
        order_height: 1600,
        order_both_width: 100,
        order_both_height: 100,
        phoneOptions: {
          phone: true,
          phoneRegionCode: 'UA',
        },
      }
    },
    metaInfo() {
      return {
        title: this.i18n.MENU.order,
        meta: [
          { vmid: 'description', property: 'description', content: 'Замовити' },
          { vmid: 'og:title', property: 'og:title', content: 'Замовити' },
          { vmid: 'og:description', property: 'og:description', content: 'Замовити' },
        ],
      }
    },
  }
</script>

<style lang="scss">
.width {
  margin: 20px 30px 0;
}
.order {
  margin: 12px 0 0;
  &__text {
    padding: 15px;
    background: #272C30;
    border: 1px solid #7D8B97;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    color: #FFFFFF;
    &:disabled {
      opacity: .3;
    }
  }
}
textarea.order__text {
  height: 110px;
  resize: vertical;
}
.user-info {
  margin: 0;
  padding: 0;
  font: 16px/200% "ProximaNovaBold",sans-serif;
  overflow: hidden;
  color: #FFFFFF;
  &__title {
    margin: 15px 0 0;
    padding: 0 10px 0 0;
    float: left;
    clear: left;
    a {
      color: #ffffff;
    }
  }
  &__text {
    margin: 0;
    padding: 0;

    color: #C2CFD3;
  }
}
.blade {
  font: 15px/18px 'ProximaNovaRegular', sans-serif;
  color: #FFFFFF;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding: 4px 40px 0 40px;
    cursor: pointer;
    font: 15px/18px 'ProximaNovaRegular',sans-serif;
    color: #FFFFFF;
    display: flex;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #7D8B97;
    border-radius: 100px;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #C70552;
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 100px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.order-panel {
  font: 15px/18px 'ProximaNovaRegular',sans-serif;
  color: #FFFFFF;
}
.order-footer {
  padding: 20px 0;
  button {
    display: inline-block;
    margin: 0;
    padding: 0 20px;
    border: none;
    height: 50px;
    background-color: #c70552;
    color: #ffffff;
    cursor: pointer;
    text-transform: uppercase;
    font: 14px/140% "ProximaNovaRegular", sans-serif;
  }
}
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #7D8B97;
  margin-right: 15px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox:checked+label::before {
  border-color: #7D8B97;
  background-color: #C70552;
  background-image: url("../../assets/images/order/check.svg");
}
/* стили при наведении курсора на checkbox */
.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: #7D8B97;
}
</style>

<style lang="scss">
.size {
  font: 15px/18px 'ProximaNovaBold', sans-serif;
  color: #FFFFFF;
  margin-top: 26px;

  &__title {
    font: 15px/18px "ProximaNovaBold", sans-serif;
  }
  &__text {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    justify-content: space-between;
    span {
      position: relative;
      label.error {
        position: absolute;
        left: 5px;
        top: 100%;
        margin-top: 2px;
        white-space: nowrap;
      }
    }
  }
  &__group {
    display: flex;
    align-items: center;
    span {
      padding-right: 10px;
      &:last-child {
        padding-right: 0;
        padding-left: 10px;
      }
    }
  }
  &__lab {
    padding-right: 5px;
  }
  &__field {
    margin: 0 5px;
    padding-left: 15px;
    overflow: hidden;
    width: 101px;
    height: 40px;
    background: #272C30;
    border: 1px solid #7D8B97;
    box-sizing: border-box;
    border-radius: 3px;
    color: #FFFFFF;
    font: 15px/18px 'proxima_nova_regular', sans-serif;
    &--full {
      flex: 1;
      width: inherit;
    }
  }
}
.additionaly {
  font: 15px/18px 'ProximaNovaRegular', sans-serif;
  color: #C2CFD3;
  margin-top: 20px;
}
</style>
